<template>
    <div :class="layoutContainerClass" @click="onDocumentClick">
        <div class="layout-main">
            <AppTopBar
                :items="filteredMenu"
                :menuMode="menuMode"
                :colorScheme="colorScheme"
                :menuActive="menuActive"
                :topbarMenuActive="topbarMenuActive"
                :activeInlineProfile="activeInlineProfile"
                @topbar-item-click="onTopbarItemClick"
                @menubutton-click="onMenuButtonClick"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <AppMenu
                :model="filteredMenu"
                :menuMode="menuMode"
                :colorScheme="colorScheme"
                :menuActive="menuActive"
                :sidebarActive="sidebarActive"
                :sidebarStatic="sidebarStatic"
                :pinActive="pinActive"
                :activeInlineProfile="activeInlineProfile"
                @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <AppBreadcrumb
                :menuMode="menuMode"
                v-model:searchActive="searchActive"
                v-model:searchClick="searchClick"
                @menubutton-click="onMenuButtonClick"
                @rightmenu-button-click="onRightMenuButtonClick"
            />

            <div class="layout-main-content">
                <router-view />
            </div>

            <AppFooter :colorScheme="colorScheme" />
        </div>

        <!-- <AppRightMenu :rightMenuActive="rightMenuActive" @rightmenu-click="onRightMenuClick" @rightmenu-active="onChangeRightMenuActive" /> -->

        <AppConfig
            v-if="isAdmin"
            v-model:menuMode="menuMode"
            :theme="theme"
            :componentTheme="componentTheme"
            :colorScheme="colorScheme"
            :configActive="configActive"
            :configClick="configClick"
            @config-button-click="onConfigButtonClick"
            @config-click="onConfigClick"
            @change-color-scheme="onChangeColorScheme"
            @change-component-theme="onChangeComponentTheme"
            @change-menu-theme="onChangeMenuTheme"
            @change-menu-mode="onChangeMenuMode"
        />
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppRightMenu from './AppRightMenu.vue';
import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import AppConfig from './AppConfig.vue';
import EventBus from './event-bus';
import moment from 'moment-timezone';
// import { PrimeIcons } from 'primevue/api';

export default {
    emits: [
        'layout-mode-change',
        'menu-theme',
        'layoutModeChange',
        'sidebar-mouse-over',
        'sidebar-mouse-leave',
        'change-color-scheme',
        'change-component-theme',
        'change-menu-theme',
        'change-inlinemenu',
    ],
    props: {
        colorScheme: String,
        topbarTheme: String,
        theme: String,
        componentTheme: String,
    },
    data() {
        return {
            moment,
            search: false,
            searchClick: false,
            searchActive: false,
            // menuMode: 'sidebar',
            menuActive: false,
            activeInlineProfile: false,
            inlineMenuClick: false,
            overlayMenuActive: false,
            topbarMenuActive: false,
            topbarItemClick: false,
            sidebarActive: true,
            sidebarStatic: true,
            pinActive: true,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    label: 'Home',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard',
                    role: ['superadmin', 'admin', 'teacher'],
                },
                {
                    label: 'ホーム',
                    icon: 'pi pi-fw pi-home',
                    to: '/dashboard',
                    role: ['student'],
                },
                // {
                //     label: 'Dashboard Teacher',
                //     icon: 'pi pi-fw pi-home',
                //     to: '/dashboard_teacher',
                //     role: ['teacher'],
                // },
                // {
                //     label: 'Dashboard Student',
                //     icon: 'pi pi-fw pi-home',
                //     to: '/dashboard_student',
                //     role: ['student'],
                // },
                {
                    label: 'Schedule',
                    icon: 'pi pi-fw pi-calendar',
                    to: '/schedule',
                    role: ['superadmin', 'admin', 'teacher'],
                },
                {
                    label: 'スケジュール',
                    icon: 'pi pi-fw pi-calendar',
                    to: '/schedule',
                    role: ['student'],
                },
                {
                    label: 'Lesson History',
                    icon: 'pi pi-fw pi-calendar-times',
                    to: '/lesson_history',
                    role: ['superadmin', 'teacher'],
                },
                {
                    label: 'レッスン履歴',
                    icon: 'pi pi-fw pi-calendar-times',
                    to: '/lesson_history',
                    role: ['student'],
                },
                {
                    label: 'Newsbank',
                    icon: 'pi pi-list',
                    to: '/all_newsbank',
                    role: ['teacher'],
                },
                {
                    // label: 'Teacher',
                    label: '講師プロフィール',
                    icon: 'pi pi-fw pi-id-card',
                    to: '/students_teacher',
                    role: ['student'],
                },
                {
                    label: 'Teacher',
                    icon: 'pi pi-fw pi-id-card',
                    to: '/students_teacher',
                    role: ['superadmin'],
                },
                {
                    label: 'Student',
                    icon: 'pi pi-fw pi-users',
                    to: '/teachers_student',
                    role: ['superadmin'],
                },
                {
                    label: 'Students',
                    icon: 'pi pi-fw pi-users',
                    to: '/teachers_student',
                    role: ['teacher'],
                },
                {
                    label: 'Evaluations',
                    icon: 'pi pi-fw pi-thumbs-up',
                    to: '/evaluation',
                    role: ['teacher', 'superadmin'],
                },
                {
                    label: 'Profile',
                    icon: 'pi pi-fw pi-id-card',
                    to: '/user_profile',
                    role: ['teacher'],
                },
                // {
                //     label: 'Teacher (Students Site)',
                //     icon: 'pi pi-fw pi-clone',
                //     to: '/students_teacher',
                //     role: ['superadmin', 'admin', 'student'],
                // },
                // {
                //     label: 'Lesson History (Student Site)',
                //     icon: 'pi pi-fw pi-clone',
                //     to: '/student_lesson_history',
                //     role: ['superadmin', 'admin', 'student'],
                // },
                // {
                //     label: 'Lesson History (Teacher Site) ',
                //     icon: 'pi pi-fw pi-clone',
                //     to: '/teacher_lesson_history',
                //     role: ['superadmin', 'admin', 'teacher'],
                // },
                // {
                //     label: 'Lesson History',
                //     icon: 'pi pi-fw pi-clone',
                //     to: '/student_lesson_history',
                //     role: ['student'],
                // },
                // {
                //     label: 'Lesson History',
                //     icon: 'pi pi-fw pi-clone',
                //     to: '/teacher_lesson_history',
                //     role: ['teacher'],
                // },
                // {
                //     label: 'Newsbank',
                //     icon: 'pi pi-fw pi-folder',
                //     to: '/newsbank',
                //     role: ['superadmin', 'admin', 'student', 'teacher'],
                // },
                {
                    label: 'Teachers',
                    icon: 'pi pi-fw pi-book',
                    to: '/teacher/profile',
                    role: ['superadmin', 'admin'],
                },
                {
                    label: 'Students',
                    icon: 'pi pi-fw pi-users',
                    to: '/students/profile',
                    role: ['superadmin', 'admin'],
                },
                {
                    label: 'Orders',
                    icon: 'pi pi-fw pi-shopping-bag',
                    to: '/orders',
                    role: ['superadmin', 'admin'],
                },
                {
                    label: 'Payment',
                    icon: 'pi pi-fw pi-credit-card',
                    to: '/payments',
                    role: ['superadmin', 'admin'],
                },
                // {
                //     label: 'Course & Fee',
                //     icon: 'pi pi-fw pi-credit-card',
                //     to: '/subscription',
                //     role: ['superadmin', 'admin'],
                // },

                {
                    // Learning Tools
                    label: '学習ツール',
                    icon: 'pi pi-fw pi-clone',
                    role: ['student'],
                    items: [{ label: 'Newsbank', icon: 'pi pi-list', to: '/all_newsbank' }],
                },
                {
                    label: 'Tools',
                    icon: 'pi pi-fw pi-clone',
                    role: ['superadmin', 'admin'],
                    items: [
                        { label: 'Teacher News', icon: 'pi pi-fw pi-folder', to: '/teacher_news' },
                        { label: 'Student News', icon: 'pi pi-fw pi-folder', to: '/student_news' },
                        { label: 'Newsbank', icon: 'pi pi-fw pi-folder', to: '/newsbank' },
                    ],
                },
                {
                    // label: 'Help & Support ',
                    label: 'ヘルプ＆サポート',
                    icon: 'pi-th-large',
                    role: ['student'],
                    items: [
                        {
                            label: 'Profile',
                            icon: 'pi pi-fw pi-id-card',
                            to: '/user_profile',
                        },
                        {
                            // label: 'Course & Fee',
                            label: 'コース・料金',
                            icon: 'pi pi-fw pi-credit-card',
                            to: '/subscription',
                        },
                        // { label: 'FAQ', icon: 'pi pi-fw pi-id-card', to: '/student_news' },
                        // {
                        //     label: 'How to Reservation & Cancelation',
                        //     icon: 'pi pi-fw pi-id-card',
                        //     to: '/newsbank',
                        // },
                        // { label: 'Course & Fee', icon: 'pi pi-fw pi-id-card', to: '/newsbank' },
                        // { label: 'Payment Method', icon: 'pi pi-fw pi-id-card', to: '/newsbank' },
                    ],
                },

                {
                    label: 'Help & Support ',
                    icon: 'pi-th-large',
                    role: ['superadmin', 'admin'],
                    items: [
                        {
                            label: 'Profile',
                            icon: 'pi pi-fw pi-id-card',
                            to: '/user_profile',
                        },
                        // { label: 'FAQ', icon: 'pi pi-fw pi-id-card', to: '/student_news' },
                        // {
                        //     label: 'How to Reservation & Cancelation',
                        //     icon: 'pi pi-fw pi-id-card',
                        //     to: '/newsbank',
                        // },
                        // { label: 'Course & Fee', icon: 'pi pi-fw pi-id-card', to: '/newsbank' },
                        // { label: 'Payment Method', icon: 'pi pi-fw pi-id-card', to: '/newsbank' },
                    ],
                },
                {
                    label: 'Masters',
                    icon: 'pi pi-fw pi-clone',
                    items: [
                        { label: 'Course', icon: 'pi pi-fw pi-circle', to: '/master/course' },
                        {
                            label: 'Course Type',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/course_type',
                        },
                        {
                            label: 'Lesson Atmosphere',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/lesson_atmosphere',
                        },
                        {
                            label: 'Level Teaching',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/level_teaching',
                        },
                        {
                            label: 'Methodology',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/methodology',
                        },
                        { label: 'Nation', icon: 'pi pi-fw pi-circle', to: '/master/nation' },
                        {
                            label: 'Payment Plan',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/payment_plan',
                        },
                        {
                            label: 'Personality',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/personality',
                        },
                        // { label: 'Role', icon: 'pi pi-fw pi-circle', to: '/master/role' },
                        { label: 'Skills', icon: 'pi pi-fw pi-circle', to: '/master/skill' },
                        { label: 'Skillset', icon: 'pi pi-fw pi-circle', to: '/master/skillset' },
                        {
                            label: 'Teacher Industry',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/teacher_industry',
                        },
                        {
                            label: 'Teaching Style',
                            icon: 'pi pi-fw pi-circle',
                            to: '/master/teaching_style',
                        },
                    ],
                    role: ['superadmin', 'admin'],
                },
            ],
        };
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
            this.$store.commit('set_timezone', this.moment.tz.guess());
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.topbarItemClick) {
                this.topbarMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                    EventBus.emit('reset-active-index');
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.overlayMenuActive = false;
                    this.staticMenuMobileActive = false;
                }

                this.hideOverlayMenu();
                this.unblockBodyScroll();
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.configClick = false;
            this.rightMenuClick = false;
            this.searchClick = false;
            this.inlineMenuClick = false;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onSidebarMouseOver() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                this.sidebarActive = this.isDesktop();
                setTimeout(() => {
                    this.pinActive = this.isDesktop();
                }, 200);
            }
        },
        onSidebarMouseLeave() {
            if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.sidebarActive = false;
                    this.pinActive = false;
                }, 250);
            }
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }

            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onRightMenuButtonClick() {
            this.rightMenuClick = true;
            this.rightMenuActive = true;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                this.hideOverlayMenu();
                EventBus.emit('reset-active-index');
            }

            if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                this.menuActive = false;
            }
        },
        onChangeMenuMode(menuMode) {
            this.$store.commit('changeMenuMode', menuMode);
            this.overlayMenuActive = false;
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick(event) {
            this.configActive = !this.configActive;
            this.configClick = true;
            event.preventDefault();
        },
        onChangeRightMenuActive(active) {
            this.rightMenuActive = active;
        },
        onChangeMenuTheme(theme) {
            this.$emit('change-menu-theme', theme);
        },
        onChangeComponentTheme(theme) {
            this.$emit('change-component-theme', theme);
        },
        onChangeColorScheme(scheme) {
            this.$emit('change-color-scheme', scheme);
        },
        onToggleMenu(event) {
            this.menuClick = true;

            if (this.overlayMenuActive) {
                this.overlayMenuActive = false;
            }

            if (this.sidebarActive) {
                this.sidebarStatic = !this.sidebarStatic;
                this.$store.commit('lock_side_bar', this.sidebarStatic);
            }

            event.preventDefault();
        },
        onChangeActiveInlineMenu() {
            this.activeInlineProfile = !this.activeInlineProfile;
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        },
        isSlim() {
            return this.menuMode === 'slim';
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isSidebar() {
            return this.menuMode === 'sidebar';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
    },
    computed: {
        menuMode() {
            return this.$store.state.livejapan.menuMode;
        },
        role() {
            return this.$store.state.livejapan.login_role ? this.$store.state.livejapan.login_role : null;
        },
        isAdmin() {
            return this.role == 'admin' || this.role == 'superadmin';
        },
        layoutContainerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-static': this.menuMode === 'static',
                    'layout-overlay': this.menuMode === 'overlay',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-slim': this.menuMode === 'slim',
                    'layout-horizontal': this.menuMode === 'horizontal',
                    'layout-active': this.menuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-sidebar': this.menuMode === 'sidebar',
                    'layout-sidebar-static': this.menuMode === 'sidebar' && this.sidebarStatic,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
                    'p-ripple-disabled': this.$primevue.config.ripple === false,
                },
            ];
        },
        filteredMenu() {
            if (this.$store.state.livejapan.aktif_role) {
                return this.menu.filter((item) => {
                    if (item.items) {
                        item.items.filter((ele) => {
                            return !ele.role || ele.role.includes(this.$store.state.livejapan.aktif_role);
                        });
                    }
                    return !item.role || item.role.includes(this.$store.state.livejapan.aktif_role);
                });
            } else {
                return this.menu;
            }
        },
    },
    components: {
        AppTopBar,
        AppMenu,
        // AppRightMenu,
        AppFooter,
        AppBreadcrumb,
        AppConfig,
    },
};
</script>

<style>
label {
    font-size: 13px;
    line-height: 16px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: rgba(37, 99, 235, 1);
    color: white;
    border-radius: 50%;
}

.transred {
    background: rgba(254, 242, 242, 0.95);
    text-align: center;
    border: solid #fecaca;
    border-width: 1px;
    color: #a71111;
    box-shadow: 0px 4px 8px 0px rgba(167, 17, 17, 0.1) !important;
    padding: 2px 8px 2px 8px;
    margin: 0;
    border-radius: 4px;
    /* font-family: 'Comic Neue', 'cursive' !important; */
    font-feature-settings: normal;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.transgray {
    background: #f1f5f9;
    border: solid #e2e8f0;
    border-width: 1px;
    color: #475569;
    box-shadow: 0px 4px 8px 0px rgba(239, 68, 68, 0.04) !important;
    padding: 2px 8px 2px 8px;
    margin: 0;
    border-radius: 4px;
    /* font-family: 'Comic Neue', 'cursive' !important; */
    font-feature-settings: normal;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.transgreen {
    background: rgba(240, 253, 244, 0.95);
    border: solid #bbf7d0;
    border-width: 1px;
    color: #11a75c;
    box-shadow: 0px 4px 8px 0px rgba(34, 197, 94, 0.04) !important;
    padding: 2px 8px 2px 8px;
    margin: 0;
    border-radius: 4px;
    /* font-family: 'Comic Neue', 'cursive' !important; */
    font-feature-settings: normal;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.transblue {
    background: rgba(239, 246, 255, 0.95);
    border: solid #bfdbfe;
    border-width: 1px;
    color: #2563eb;
    box-shadow: 0px 4px 8px 0px rgba(59, 130, 246, 0.04) !important;
    padding: 2px 8px 2px 8px;
    margin: 0;
    border-radius: 6px;
    /* font-family: 'Comic Neue', 'cursive' !important; */
    font-feature-settings: normal;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.elipsis-info {
    min-height: 48px;
    /* font-size: 14px; */
    font-size: 15px;
    line-height: 24px;
    color: #202020;
    font-weight: 400;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.elipsis-info-group {
    min-height: 96px;
    /* font-size: 14px; */
    font-size: 15px;
    line-height: 24px;
    color: #202020;
    font-weight: 400;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.elipsis-table {
    min-height: 48px;
    /* font-size: 14px; */
    font-size: 15px;
    line-height: 24px;
    color: #202020;
    font-weight: 400;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.elipsis-comment {
    min-height: 120px;
    /* font-size: 14px; */
    font-size: 15px;
    line-height: 24px;
    color: #202020;
    font-weight: 400;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.elipsis-comment15 {
    min-height: 120px;
    /* font-size: 14px; */
    font-size: 15px;
    line-height: 24px;
    color: #202020;
    font-weight: 400;
    letter-spacing: -0.2px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 50;
    -webkit-box-orient: vertical;
}
.time {
    color: #202020;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
}

.card-label-info {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03px;
    font-weight: 600;
    color: #202020;
    text-align: start;
}
.card-label-title {
    /* styleName: paragraph 5/semi bold;
     font-family: Inter; */
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #afafaf;
}
.card-text-info {
    /* //styleName: paragraph 3/semi bold; */
    /* font-family: Inter; */
    /* font-size: 14px; */
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
}

.card-news-title {
    /* //styleName: paragraph 5/regular;
    font-family: Inter; */
    font-size: 20px;
    font-weight: 550;
    line-height: 30px;
    letter-spacing: -0.2px;
    text-align: left;
    color: rgba(32, 32, 32, 1);
}

.date-secondary-label {
    /* //styleName: paragraph 5/semi bold; */
    /* font-family: Inter; */
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #7c7c7c;
}

.date-modal-label {
    /* //styleName: paragraph 5/semi bold; */
    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #7c7c7c;
}
.name-modal {
    /* //styleName: paragraph 5/semi bold;
font-family: Inter; */
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #202020;
    text-transform: capitalize;
}
.role-modal {
    /* //styleName: paragraph 6/regular;
    font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #202020;
    text-transform: capitalize;
}
.cancel-modal {
    /* //styleName: paragraph 6/regular;
    font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.2px;
    text-align: left;
    color: #000000;
    text-transform: capitalize;
}

/* big avatar */

.img-circle-small {
    width: 12vw;
    height: 12vw;
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border: 2px solid #ccc;
    margin-bottom: 2px;
    background-color: #2563eb;
    cursor: pointer;
    object-fit: cover;
}

.status {
    width: 48px;
    height: 48px;
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border: 2px solid #ccc;
    margin-bottom: 2px;
    background-color: white;
    position: absolute;
    font-size: 48px;
}

.temp {
    position: relative;
    display: inline-block;
}
.topRight {
    bottom: 0.7vw;
    right: 0.7vw;
}

.icon {
    vertical-align: middle;
    position: absolute;
    top: 30%;
    right: 25%;
    font-size: 1.5rem;
    cursor: pointer;
}

[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 7em;
    width: 12vw;
    height: 12vw;
    line-height: 12vw;
    text-align: center;
    border-radius: 50%;
    background: #2563eb;
    vertical-align: middle;
    text-transform: capitalize;
    /* margin-right: 1em; */
    color: white;
}

@media only screen and (max-width: 600px) {
    .img-circle-small {
        width: 95px;
        height: 95px;
        border-top-left-radius: 50% 50%;
        border-top-right-radius: 50% 50%;
        border-bottom-right-radius: 50% 50%;
        border-bottom-left-radius: 50% 50%;
        border: 2px solid #ccc;
        margin-bottom: 2px;
        object-fit: cover;
    }

    .status {
        visibility: hidden;
    }

    .temp {
        position: relative;
        display: inline-block;
    }
    .topRight {
        bottom: 1vw;
        right: 1vw;
    }

    [data-letters]:before {
        content: attr(data-letters);
        display: inline-block;
        font-size: 50px;
        width: 95px;
        height: 95px;
        line-height: 95px;
        text-align: center;
        border-radius: 50%;
        background: #2563eb;
        vertical-align: middle;
        text-transform: capitalize;
        /* margin-right: 1em; */
        color: white;
    }
}

/* medium avatar */
.img-circle-small-med {
    width: 64px;
    height: 64px;
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border: 2px solid #ccc;
    margin-bottom: 2px;
    background-color: #2563eb;
    object-fit: cover;
}

.tempcard {
    position: relative;
    display: inline-block;
}
.topRightcard {
    bottom: 1vw;
    right: 1vw;
}

[data-letters-med]:before {
    content: attr(data-letters-med);
    display: inline-block;
    font-size: 32px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    border-radius: 50%;
    background: #2563eb;
    vertical-align: middle;
    text-transform: capitalize;
    /* margin-right: 1em; */
    color: white;
}

/* medium avatar */
.img-circle-small-sm {
    width: 44px;
    height: 44px;
    border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border: 2px solid #ccc;
    margin-bottom: 2px;
    background-color: rgba(37, 99, 235, 1);
    object-fit: cover;
}

[data-letters-sm]:before {
    content: attr(data-letters-sm);
    display: inline-block;
    font-size: 22px;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 50%;
    background: rgba(37, 99, 235, 1);
    vertical-align: middle;
    color: white;
    text-transform: capitalize;
    /* margin-right: 1em; */
    /* color: white; */
}

.slide-fade-enter-active {
    transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

::v-deep(.p-avatar img) {
    object-fit: cover !important;
}

img {
    object-fit: cover !important;
}

.font-14-no-color {
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
}

.dont-break {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}
</style>
