import axios from 'axios';
import router from '../router';
import store from '@/store/index.js';

// local
// const ip = 'http://192.168.123.189:5008/';
// dev
// const ip = 'https://backendlivejapan-ykqw6xki6a-an.a.run.app';
// prod
const ip = 'https://backendlivejapan-waouex2joa-an.a.run.app';

const instance = axios.create({
    baseURL: ip,
    // mode: 'no-cors',
});

instance.interceptors.request.use(async (req) => {
    // return req;
    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    let token = store.state.livejapan.aktif_login_token;
    if (!token) {
        token = store.state.livejapan.aktif_login_token;
        req.headers.Authorization = `Bearer ${token}`;
        return req;
    } else {
        token = store.state.livejapan.aktif_login_token;
        req.headers.Authorization = `Bearer ${token}`;
        return req;
    }
});

instance.interceptors.response.use(async (res) => {
    // console.log(res.data.message, 'axios');
    if (res.data.message == 'anda belum login') {
        // if (res.data.status == 200) {
        store.commit('login_warning', { severity: 'error', summary: 'Warning', detail: 'Your session has expired, please relogin', life: 3000 });
        setTimeout(() => {
            store.commit('set_clear_token');
            router.push('/');
        }, 3000);
        return res;
    } else {
        return res;
    }
});

export default instance;
